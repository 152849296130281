.headroom {
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
}

.headroom--unfixed {
  position: relative;
  transform: translateY(0);
}

.headroom--scrolled {
  transition: transform 0.5s ease-in-out;
}

.headroom--unpinned {
  position: fixed;
  transform: translateY(-100%);
}

.headroom--pinned {
  position: fixed;
  transform: translateY(0%);
}
