@charset 'UTF-8';

@import url('https://fonts.googleapis.com/css2?family=Lora:ital@1&display=swap');

@font-face {
  font-family: 'Causten Round';
  src:
    url('/fonts/causten-round/causten-round-regular-webfont.woff2') format('woff2'),
    url('/fonts/causten-round/causten-round-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Causten Round';
  src:
    url('/fonts/causten-round/causten-round-regular-oblique-webfont.woff2') format('woff2'),
    url('/fonts/causten-round/causten-round-regular-oblique-webfont.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Causten Round';
  src:
    url('/fonts/causten-round/causten-round-medium-webfont.woff2') format('woff2'),
    url('/fonts/causten-round/causten-round-medium-webfont.woff') format('woff');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Causten Round';
  src:
    url('/fonts/causten-round/causten-round-bold-webfont.woff2') format('woff2'),
    url('/fonts/causten-round/causten-round-bold-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Causten';
  src:
    url('/fonts/causten/causten-regular-webfont.woff2') format('woff2'),
    url('/fonts/causten/causten-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Causten';
  src:
    url('/fonts/causten/causten-medium-webfont.woff2') format('woff2'),
    url('/fonts/causten/causten-medium-webfont.woff') format('woff');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Causten';
  src:
    url('/fonts/causten/causten-bold-webfont.woff2') format('woff2'),
    url('/fonts/causten/causten-bold-webfont.woff') format('woff');
  font-weight: 600;
  font-display: swap;
}
