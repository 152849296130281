html {
  margin: 0;
  font-size: 16px;
  font-family: 'Causten Round', Helvetica, Arial, sans-serif;
  scroll-behavior: smooth;
}

body,
html {
  padding: 0;
  min-height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  width: 100%;
  color: #666;
  font-size: 1rem;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -moz-osx-font-smoothing: grayscale;
}

::-moz-selection {
  background: #8792b5;
  color: #fff;
}

::selection {
  background: #8792b5;
  color: #fff;
}

header {
  flex: none;
}

main {
  flex: 1 0 auto;
  overflow: hidden;
  width: 100%;
}

footer {
  flex: none;
  margin-top: auto;
  min-height: 480px;
  width: 100%;
  background: #fff;
}

sub,
sup {
  vertical-align: baseline;
  position: relative;
}

sup {
  top: -0.25rem;
}

sub {
  bottom: -0.25rem;
}

a {
  color: #0fcdba;
  cursor: pointer;
  transition: color 0.3s;
}

a,
a:hover {
  text-decoration: none;
}

a:hover {
  color: #1f9971;
}

a:not('.button'):active {
  color: #8792b5;
}

a:focus {
  outline: none;
}

ol,
ul {
  margin-bottom: 1em;
  padding: 0;
  list-style: none;
  font-size: inherit;
}

ol > li,
ul > li {
  position: relative;
  margin-top: 0.5em;
  text-align: left;
}

ul:not([class*='list-unstyled']) > li {
  padding-left: 45px;
  line-height: 1.5;
}

ul:not([class*='list-unstyled']) > li:before {
  position: absolute;
  left: 12px;
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  color: inherit;
  content: '•';
  font-weight: 700;
}

ul:not([class*='list-unstyled']).no-bullets > li {
  padding: 0;
}

ul:not([class*='list-unstyled']).no-bullets > li:before {
  padding: 0;
  content: '';
}

ul:not([class*='list-unstyled']).arrows > li:before {
  padding-left: 0;
  padding-right: 15px;
  color: inherit;
  content: '▻';
  font-weight: 400;
  font-size: 0.85em;
}

ul:not([class*='list-unstyled']).checks > li:before {
  padding-left: 0;
  padding-right: 15px;
  color: #0fcdba;
  content: '✓';
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
    'Open Sans', 'Helvetica Neue', sans-serif;
  line-height: 1;
  font-weight: 400;
  font-size: 1.5em;
  text-align: center;
}

/** DatoCMS styles... TODO: Get rid of this. */
.dato-cms {
  width: 100%;
}

.dato-cms li p {
  margin-left: 12px;
}

/* THIS CLASS IS USED WITHIN THE BLOCK RENDERER COMPONENT */
.dato-cms ul:not([class*='list-unstyled']) > li:before {
  padding-left: 0;
  padding-right: 15px;
  color: #0fcdba;
  content: '✓';
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
    'Open Sans', 'Helvetica Neue', sans-serif;
  line-height: 1;
  font-weight: 400;
  font-size: 1.5em;
  text-align: center;
}

.dato-cms hr {
  max-width: 150px;
  height: 1px;
  background-color: lightgray;
  border: none;
  margin: 12px 0px;
  margin-right: auto;
}

ul:not([class*='list-unstyled']).crosses > li:before {
  padding-left: 0;
  padding-right: 15px;
  color: #d12424;
  content: '\2421';
  font-weight: 400;
  font-size: 1.5em;
  text-align: center;
}

ul:not([class*='list-unstyled']).pros > li:before {
  padding-left: 0;
  padding-right: 15px;
  color: #0fcdba;
  content: '+';
  font-weight: 400;
  line-height: 1;
  font-size: 1.5em;
  text-align: center;
}

ul:not([class*='list-unstyled']).cons > li:before {
  padding-left: 0;
  padding-right: 15px;
  color: #d12424;
  content: '-';
  font-weight: 400;
  font-size: 1.5em;
  text-align: center;
}

ul:not([class*='list-unstyled']).questions > li:before {
  padding-left: 0;
  padding-right: 15px;
  color: inherit;
  content: '\EE11';
  font-weight: 400;
  font-size: 1.5em;
  text-align: center;
}

ul:not([class*='list-unstyled']).checkboxes > li:before {
  padding-left: 0;
  padding-right: 15px;
  position: absolute;
  top: 50%;
  right: auto;
  left: 12px;
  transform: translateY(-50%);
  overflow: hidden;
  width: 1em;
  height: 1em;
  border: 1px solid #ccc;
  border-radius: 3px;
  background: #fff;
  content: '';
  vertical-align: middle;
  transition: all 0.125s;
}

ul:not([class*='list-unstyled']).checkboxes > li.checked:before {
  color: #0fcdba;
  content: '✓';
  vertical-align: middle;
  text-decoration: none;
  font-weight: 400;
  line-height: 1.2;
}

ol {
  counter-reset: item;
}

ol > li {
  padding-left: 45px;
  counter-increment: item;
}

ol > li:before {
  position: absolute;
  left: 6px;
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  border-radius: 100px;
  background: #0fcdba;
  color: #fff;
  content: counter(item);
  text-align: center;
  font-weight: 500;
}

ol > li.red-li:before {
  background: #d12424;
}

ol > li.gold-li:before {
  background: #faaf40;
}

ol > li.blue-li:before {
  background: #4bb7e2;
}

ol > li.purple-li:before {
  background: #8792b5;
}

ol > li.orange-li:before {
  background: #fc6e51;
}

li > ol,
li > ul {
  margin-top: 0.5rem;
}

li > ol,
li > ol > li,
li > ul,
li > ul > li {
  margin-bottom: 0;
}

input::-webkit-input-placeholder {
  color: #ccc;
  opacity: 1;
  transition: opacity 0.25s;
}

input:-moz-placeholder,
input::-moz-placeholder {
  color: #ccc;
  opacity: 1;
  transition: opacity 0.25s;
}

input:-ms-input-placeholder {
  color: #ccc !important;
  opacity: 1;
  transition: opacity 0.25s;
}

input::-ms-input-placeholder {
  color: #ccc !important;
  opacity: 1;
  transition: opacity 0.25s;
}

input:focus::-webkit-input-placeholder {
  opacity: 0;
}

input:focus:-moz-placeholder,
input:focus::-moz-placeholder {
  opacity: 0;
}

input:focus:-ms-input-placeholder {
  opacity: 0;
}

input:focus::-ms-input-placeholder {
  opacity: 0;
}

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}

textarea {
  resize: both;
}

textarea.no-resize {
  resize: none;
}

textarea.v-resize {
  resize: vertical;
}

textarea.h-resize {
  resize: horizontal;
}

input[type='range'] {
  margin: 0;
  padding: 0;
  width: 100%;
  cursor: pointer;
  transition: background 0.25s;
  -webkit-appearance: none;
}

input[type='range']:focus {
  outline: none;
}

input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 6px;
  border: 1px solid #25b887;
  border-radius: 100px;
  background: #0fcdba;
}

input[type='range']::-webkit-slider-thumb {
  margin-top: -8px;
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
  border-radius: 50%;
  background: #eee;
  -webkit-appearance: none;
}

input[type='range']:focus::-webkit-slider-runnable-track {
  background: #25b887;
}

input[type='range']:focus::-webkit-slider-thumb {
  background: #d6d6d6;
}

input[type='range']::-moz-range-track {
  width: 100%;
  height: 6px;
  border: 1px solid #25b887;
  border-radius: 100px;
  background: #0fcdba;
}

input[type='range']::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
  border-radius: 50%;
  background: #eee;
}

input[type='range']:focus::-moz-range-track {
  background: #25b887;
}

input[type='range']::-ms-track {
  border-width: 20px;
  border-color: transparent;
  border-radius: 6px;
  background: transparent;
  color: transparent;
}

input[type='range']::-ms-fill-lower {
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  border: 1px solid #25b887;
  background: #0fcdba;
}

input[type='range']::-ms-fill-upper {
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  border: 1px solid #25b887;
  background: rgba(185, 238, 220, 0.9);
}

input[type='range']::-ms-thumb {
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
  border-radius: 50%;
  background: #eee;
}

input[type='range']::-ms-tooltip {
  display: none;
}

input[type='range']:focus::-ms-fill-lower {
  background: #25b887;
}

input[type='range']:focus::-ms-fill-upper {
  background: rgba(185, 238, 220, 0.9);
}

[aria-haspopup='true'] {
  cursor: help;
}
