.hide {
  display: none !important;
}

.show {
  display: block !important;
}

@media screen and (max-width: 424px) {
  .hide-sm {
    display: none !important;
  }
}

@media screen and (min-width: 425px) and (max-width: 767px) {
  .hide-md {
    display: none !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .hide-lg {
    display: none !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .hide-xl {
    display: none !important;
  }
}

@media screen and (min-width: 1200px) {
  .hide-xxl {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {
  .hide-mobile {
    display: none !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .hide-tablet {
    display: none !important;
  }
}

@media screen and (min-width: 1024px) {
  .hide-desktop {
    display: none !important;
  }
}

.show-sm {
  display: none !important;
}

@media screen and (max-width: 424px) {
  .show-sm {
    display: block !important;
  }

  .show-sm--inline-block {
    display: inline-block !important;
  }
}

.show-md {
  display: none !important;
}

@media screen and (min-width: 425px) and (max-width: 767px) {
  .show-md {
    display: block !important;
  }

  .show-md--inline-block {
    display: inline-block !important;
  }
}

.show-lg {
  display: none !important;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .show-lg {
    display: block !important;
  }

  .show-lg--inline-block {
    display: inline-block !important;
  }
}

.show-xl {
  display: none !important;
}

@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .show-xl {
    display: block !important;
  }

  .show-xl--inline-block {
    display: inline-block !important;
  }
}

.show-xxl {
  display: none !important;
}

@media screen and (min-width: 1200px) {
  .show-xxl {
    display: block !important;
  }

  .show-xxl--inline-block {
    display: inline-block !important;
  }
}

.show-mobile {
  display: none !important;
}

@media screen and (max-width: 767px) {
  .show-mobile {
    display: block !important;
  }

  .show-mobile--inline-block {
    display: inline-block !important;
  }
}

.show-tablet {
  display: none !important;
}

@media screen and (max-width: 1023px) {
  .show-tablet {
    display: block !important;
  }

  .show-tablet--inline-block {
    display: inline-block !important;
  }
}

.show-desktop {
  display: none !important;
}

@media screen and (min-width: 1024px) {
  .show-desktop {
    display: block !important;
  }

  .show-desktop--inline-block {
    display: inline-block !important;
  }
}

.show-desktop-lg {
  display: none !important;
}

@media screen and (min-width: 1920px) {
  .show-desktop-lg {
    display: block !important;
  }

  .show-desktop-lg--inline-block {
    display: inline-block !important;
  }
}

.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: transparent;
}

.block {
  display: block !important;
}

.inline-block {
  display: inline-block !important;
}

.inline {
  display: inline !important;
}

.inline-table {
  display: inline-table !important;
}

.inline-table,
.table {
  table-layout: fixed !important;
}

.table {
  display: table !important;
  width: 100%;
}

.table-row {
  display: table-row !important;
}

.table-cell {
  display: table-cell !important;
}

.full-height {
  height: 100% !important;
}

.full-width {
  width: 100% !important;
}

.half-width {
  width: 50% !important;
}

.third-width {
  width: 33.33333% !important;
}

.quarter-width {
  width: 25% !important;
}

.page-height {
  min-height: calc(100vh - 470px) !important;
}

.has-footer {
  margin-bottom: -240px;
}

@media (min-height: 768px) {
  .has-footer {
    margin-bottom: -480px;
  }
}

.img-responsive {
  display: block;
  margin-right: auto;
  margin-left: auto;
  max-width: 100%;
  height: auto;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-justify {
  text-align: justify !important;
}

.va-top {
  vertical-align: top;
}

.va-middle {
  vertical-align: middle !important;
}

.va-bottom {
  vertical-align: bottom;
}

.va-baseline {
  vertical-align: baseline;
}

.va-sub {
  vertical-align: sub;
}

.va-super {
  vertical-align: super;
}

.pull-left {
  float: left !important;
}

.pull-right {
  float: right !important;
}

.margin-auto {
  margin-right: auto;
  margin-left: auto;
}

.absolute-center {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.clearfix:after,
.clearfix:before {
  display: table;
  content: ' ';
}

.clearfix:after {
  clear: both;
}

.relative {
  position: relative !important;
}

.absolute {
  position: absolute !important;
}

.smaller {
  font-size: 0.75em !important;
}

.regular,
.small {
  font-size: 0.85em !important;
}

@media (min-width: 425px) {
  .regular {
    font-size: 1rem !important;
  }
}

.large {
  font-size: 1rem !important;
}

@media (min-width: 425px) {
  .large {
    font-size: 1.15em !important;
  }
}

.larger {
  font-size: 1rem !important;
}

@media (min-width: 425px) {
  .larger {
    font-size: 1.15em !important;
  }
}

@media (min-width: 768px) {
  .larger {
    font-size: 1.5em !important;
  }
}

.caps {
  text-transform: uppercase !important;
}

.title-case {
  text-transform: capitalize !important;
}

.no-case {
  text-transform: none !important;
}

.italic {
  font-style: italic;
}

.nowrap {
  white-space: nowrap;
}

.tight {
  letter-spacing: -0.5px;
}

.tighter {
  letter-spacing: -0.025em;
}

.loose {
  letter-spacing: 0.5px;
}

.looser {
  letter-spacing: 0.025em;
}

.ellipsis {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.overflow-x {
  overflow-x: visible !important;
}

.overflow-y {
  overflow-y: visible !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.strikethrough {
  position: relative;
}

.strikethrough:before {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  border-color: #d12424;
  border-top: 1px solid;
  content: '';
  -webkit-transform: rotate(-5deg);
  -moz-transform: rotate(-5deg);
  -ms-transform: rotate(-5deg);
  -o-transform: rotate(-5deg);
  transform: rotate(-5deg);
}

.title-case:first-letter {
  text-transform: uppercase;
}

.no-spacing {
  letter-spacing: 0 !important;
}

.no-wrap {
  white-space: nowrap !important;
}

.inline-icon {
  margin-right: 6px;
}

.bold {
  font-weight: 700;
}

.medium {
  font-weight: 500;
}

.normal {
  font-weight: 400;
}

.no-top-margin {
  margin-top: 0 !important;
}

.no-right-margin {
  margin-right: 0 !important;
}

.no-bottom-margin {
  margin-bottom: 0 !important;
}

.no-left-margin {
  margin-left: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.border-radius--sm {
  border-radius: 3px;
}

.border-radius--md {
  border-radius: 6px;
}

.border-radius--100 {
  border-radius: 100px;
}

.border-radius--full {
  border-radius: 50%;
}

.divider {
  margin: 18px auto;
  height: 1px;
  border-bottom: 1px solid #eee;
}

.divider--xs {
  margin: 6px auto;
}

.divider--sm {
  margin: 12px auto;
}

.divider--lg {
  margin: 30px auto;
}

.divider--xl {
  margin: 60px auto;
}

.divider--25 {
  width: 25%;
}

.divider--50 {
  width: 50%;
}

.divider--75 {
  width: 75%;
}

.divider--black {
  border-color: #666;
}

.divider--dark {
  border-color: #999;
}

.divider--white {
  border-color: #fff;
}

.divider--green {
  border-color: #72ddba;
}

.deb-divider {
  margin-top: 12px;
  margin-bottom: 12px;
  text-align: center;
}

.deb-divider:before {
  display: block;
  margin: 0 auto;
  width: 50px;
  height: 50px;
  content: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='10'%3E%3Ccircle fill='%2329CC96' cx='8' cy='5' r='4'/%3E%3Ccircle fill='%2329CC96' cx='42' cy='5' r='4'/%3E%3Ccircle fill='%2329CC96' cx='25' cy='5' r='4'/%3E%3C/svg%3E");
  background-position: 50%;
  background-size: contain;
  transition: all 0.25s;
}

.no-pointer-events {
  pointer-events: none !important;
}

.default-cursor {
  cursor: default;
}

.pointer {
  cursor: pointer;
}

.no-selection {
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
}

.module {
  margin-bottom: 15px;
  position: relative;
  min-width: 290px;
  border-radius: 3px;
  background: #fff;
  box-shadow:
    0 2px 7px -2px rgba(33, 163, 120, 0.2),
    0 3px 4px 0 rgba(0, 0, 0, 0.1);
}

@media (min-width: 768px) {
  .module {
    margin-bottom: 30px;
  }
}

.grayscale {
  filter: url("data:image/svg+xml;utf8, <svg xmlns='https://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>%23grayscale");
  filter: gray;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  transition: all 0.25s ease;
}

.undo-grayscale {
  filter: url("data:image/svg+xml;utf8, <svg xmlns='https://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0'/></filter></svg>%23grayscale");
  -webkit-filter: grayscale(0);
  transition: all 0.25s ease;
}

.disabled {
  opacity: 0.5;
  transition: all 0.25s;
  pointer-events: none !important;
}

.anchor-offset:before {
  display: block;
  visibility: hidden;
  margin-top: -100px;
  height: 100px;
  content: ' ';
}

.green {
  color: #0fcdba;
}

.white {
  color: #fff;
}

.black {
  color: #000;
}

.gray-black {
  color: #333;
}

.gray-darker {
  color: #666;
}

.gray-dark {
  color: #999;
}

.gray {
  color: #ccc;
}

.purple {
  color: #8792b5;
}

.gold {
  color: #faaf40;
}

.red {
  color: #d12424;
}

.orange {
  color: #fc6e51;
}

.blue {
  color: #4bb7e2;
}

.transition-wrapper {
  min-height: calc(100vh - 520px) !important;
  width: 100%;
}

@media print {
  .transition-wrapper {
    overflow: hidden;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  overflow: hidden;
  padding: 0;
  height: 0;
}

.embed-responsive--4x3 {
  padding-bottom: 75%;
}

.embed-responsive--16x9 {
  padding-bottom: 56.25%;
}

.embed-responsive__item,
.embed-responsive iframe {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  border: 0;
}

[class*='link-'] {
  cursor: pointer;
  transition: all 0.25s;
}

.image-link:after {
  display: none !important;
}

.link-green {
  color: #0fcdba !important;
}

.link-green:focus,
.link-green:hover {
  color: #25b887 !important;
}

.link-green:active {
  color: #21a378 !important;
}

.link-green-dark {
  color: #25b887 !important;
}

.link-green-dark:focus,
.link-green-dark:hover {
  color: #21a378 !important;
}

.link-green-dark:active {
  color: #1e936c !important;
}

.link-white {
  color: #fff !important;
}

.link-white:focus,
.link-white:hover {
  color: #25b887 !important;
}

.link-white:active {
  color: #21a378 !important;
}

.link-gray {
  color: #666 !important;
}

.link-gray:focus,
.link-gray:hover {
  color: #0fcdba !important;
}

.link-lightgray {
  color: #ccc !important;
}

.link-lightgray:focus,
.link-lightgray:hover {
  color: #999 !important;
}

.link-lightgray:active {
  color: #666 !important;
}

.link-lightgray {
  color: #ccc;
}

.link-lightgray:hover {
  color: #666;
}

.list-unstyled {
  margin: 0;
  padding: 0;
  list-style: none;
}

.list-unstyled li {
  margin-bottom: 0;
  text-align: inherit;
}

.list-unstyled li:before {
  content: none !important;
}
