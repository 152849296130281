@keyframes slide-in {
  to {
    transform: translateX(0);
  }
}

@keyframes slide-in-left {
  0% {
    transform: translateX(-100vw);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slide-in-right {
  0% {
    transform: translateX(100vw);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slide-out-left {
  0% {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100vw);
  }
}

@keyframes slide-out-right {
  0% {
    transform: translateX(0);
  }

  to {
    transform: translateX(100vw);
  }
}

@keyframes float-in {
  to {
    transform: translateY(0);
  }
}

@keyframes float-in-bottom {
  0% {
    opacity: 0;
    transform: translateY(50%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes float-in-top {
  0% {
    opacity: 0;
    transform: translateY(-50%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes spin {
  to {
    transform: rotate(1turn);
  }
}

@keyframes spinner-bounce {
  0%,
  to {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }
}

@keyframes checkmark {
  0% {
    width: 0;
    height: 0;
    opacity: 1;
  }

  20% {
    width: 24px/2/2;
    height: 0;
    opacity: 1;
  }

  40% {
    width: 24px/2/2;
    height: 24px/2;
    opacity: 1;
  }

  to {
    width: 24px/2/2;
    height: 24px/2;
    opacity: 1;
  }
}

@keyframes tri-dot {
  0%,
  80%,
  to {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes joyride-tooltip {
  0% {
    transform: scale(0.1);
  }

  to {
    opacity: 1;
    transform: perspective(1px) scale(1);
  }
}

@keyframes scroll-down {
  0%,
  to {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-30px);
  }
}

@keyframes scroll-up {
  0%,
  to {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-60px);
  }
}

@keyframes hovering {
  0%,
  to {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-2.5%);
  }
}

@keyframes hovering-shadow {
  0%,
  to {
    transform: scale(1);
  }

  50% {
    transform: scale(0.9);
  }
}

@keyframes glitch {
  0% {
    clip: rect(95px, 9999px, 35px, 0);
  }

  5% {
    clip: rect(55px, 9999px, 34px, 0);
  }

  10% {
    clip: rect(41px, 9999px, 71px, 0);
  }

  15% {
    clip: rect(88px, 9999px, 76px, 0);
  }

  20% {
    clip: rect(12px, 9999px, 82px, 0);
  }

  25% {
    clip: rect(1px, 9999px, 88px, 0);
  }

  30% {
    clip: rect(40px, 9999px, 98px, 0);
  }

  35% {
    clip: rect(2px, 9999px, 18px, 0);
  }

  40% {
    clip: rect(67px, 9999px, 72px, 0);
  }

  45% {
    clip: rect(86px, 9999px, 52px, 0);
  }

  50% {
    clip: rect(2px, 9999px, 88px, 0);
  }

  55% {
    clip: rect(37px, 9999px, 71px, 0);
  }

  60% {
    clip: rect(26px, 9999px, 69px, 0);
  }

  65% {
    clip: rect(48px, 9999px, 85px, 0);
  }

  70% {
    clip: rect(76px, 9999px, 92px, 0);
  }

  75% {
    clip: rect(13px, 9999px, 25px, 0);
  }

  80% {
    clip: rect(3px, 9999px, 10px, 0);
  }

  85% {
    clip: rect(71px, 9999px, 2px, 0);
  }

  90% {
    clip: rect(2px, 9999px, 39px, 0);
  }

  95% {
    clip: rect(13px, 9999px, 50px, 0);
  }

  to {
    clip: rect(39px, 9999px, 21px, 0);
  }
}

@keyframes glitch-2 {
  0% {
    clip: rect(15px, 9999px, 93px, 0);
  }

  5% {
    clip: rect(80px, 9999px, 54px, 0);
  }

  10% {
    clip: rect(18px, 9999px, 15px, 0);
  }

  15% {
    clip: rect(91px, 9999px, 80px, 0);
  }

  20% {
    clip: rect(94px, 9999px, 56px, 0);
  }

  25% {
    clip: rect(40px, 9999px, 30px, 0);
  }

  30% {
    clip: rect(95px, 9999px, 82px, 0);
  }

  35% {
    clip: rect(82px, 9999px, 1px, 0);
  }

  40% {
    clip: rect(8px, 9999px, 21px, 0);
  }

  45% {
    clip: rect(68px, 9999px, 13px, 0);
  }

  50% {
    clip: rect(79px, 9999px, 16px, 0);
  }

  55% {
    clip: rect(84px, 9999px, 20px, 0);
  }

  60% {
    clip: rect(42px, 9999px, 25px, 0);
  }

  65% {
    clip: rect(78px, 9999px, 70px, 0);
  }

  70% {
    clip: rect(32px, 9999px, 85px, 0);
  }

  75% {
    clip: rect(88px, 9999px, 95px, 0);
  }

  80% {
    clip: rect(60px, 9999px, 86px, 0);
  }

  85% {
    clip: rect(54px, 9999px, 38px, 0);
  }

  90% {
    clip: rect(18px, 9999px, 37px, 0);
  }

  95% {
    clip: rect(40px, 9999px, 60px, 0);
  }

  to {
    clip: rect(82px, 9999px, 87px, 0);
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.slide-in {
  animation-name: slide-in !important;
  animation-fill-mode: forwards;
}

.slide-in--right {
  transform: translateX(100vw);
  animation-name: slide-in-right !important;
  animation-fill-mode: forwards;
}

.slide-in--left {
  transform: translateX(-100vw);
  animation-name: slide-in-left !important;
  animation-fill-mode: forwards;
}

.slide-out--right {
  transform: translateX(0);
  animation-name: slide-out-right !important;
  animation-fill-mode: forwards;
}

.slide-out--left {
  transform: translateX(0);
  animation-name: slide-out-left !important;
  animation-fill-mode: forwards;
}

.float-in {
  animation-name: float-in !important;
  animation-fill-mode: forwards;
}

.float-in--bottom {
  opacity: 0;
  animation-name: float-in-bottom !important;
  animation-fill-mode: forwards;
}

.float-in--top {
  opacity: 0;
  animation-name: float-in-top !important;
  animation-fill-mode: forwards;
}

.tri-dot {
  text-align: center;
}

.tri-dot .dot {
  display: inline-block;
  margin-right: 3px;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  animation: tri-dot 1.4s ease-in-out infinite both;
}

.tri-dot .dot--1 {
  animation-delay: -0.32s;
}

.tri-dot .dot--2 {
  animation-delay: -0.16s;
}

.tri-dot .dot--3 {
  margin-right: 0;
  animation-delay: 0;
}

.tri-dot--green .dot {
  background: #0fcdba;
}

.tri-dot--gold .dot {
  background: #faaf40;
}

.tri-dot--gray .dot {
  background: #999;
}

.spin,
.ss-spin:before {
  animation: spin 2s linear infinite both;
}

.scroll-down {
  animation: scroll-down 1.5s infinite;
}

.scroll-up {
  animation: scroll-up 3s 1;
  animation-timing-function: ease-in;
  animation-delay: 3s;
}

.hovering {
  animation: hovering 5s infinite;
}

.hovering__shadow {
  animation: hovering-shadow 5s infinite;
}

.glitch {
  position: relative;
}

.glitch:after {
  right: 0;
  left: 3px;
  text-shadow: -1px 0 #d12424;
  animation: glitch 2s linear infinite alternate-reverse;
}

.glitch:after,
.glitch:before {
  position: absolute;
  top: 0;
  overflow: hidden;
  clip: rect(0, 900px, 0, 0);
  background: transparent;
  color: #0fcdba;
  content: attr(data-text);
}

.glitch:before {
  right: 3px;
  left: 0;
  text-shadow: 1px 0 #8792b5;
  animation: glitch-2 3s linear infinite alternate-reverse;
}

.error-shake {
  transform: translateZ(0);
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  animation-iteration-count: 1;
  backface-visibility: hidden;
  perspective: 1000px;
}

.circle-loader {
  position: relative;
  display: inline-block;
  margin: 0 0 30px 10px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-left-color: #0fcdba;
  vertical-align: top;
  animation: spin 1s linear infinite;
}

.circle-loader,
.circle-loader:after {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.load-complete {
  border-color: '#fff';
  transition: border 0.5s ease-out;
  animation: none;
}
